import '../sass/main.scss';

import $ from 'jquery';
window.$ = window.jQuery = $;
import 'slick-carousel'; 
import LazyLoad from 'vanilla-lazyload';

var marquee = require('jquery.marquee');

var siteGlobal = {
  initReady: function () {
  },
  initLoad: function () {
  	this.lazyLoad();
    this.menuInit();
    this.scrollToTop();
    this.homeServSlider();
    this.metodoSlider();
    this.partnerSlider();
    this.pageMarqueeInit();
    this.toggleOrari();
  },
  resize: function(){
    this.homeServSlider();
    this.metodoSlider();
    this.partnerSlider();
  },
  initScroll: function(){
    this.headerScroll();
  },
  lazyLoad: function(){
    var ll = new LazyLoad({
        elements_selector: '.lazy',
        use_native: true,
        thresold: 0
    })
  },
  headerScroll: function(){
    var winScroll = window.scrollY;
    if (winScroll > 30) {
      $('.header').addClass('scrolled');
    } else if (winScroll < 30) {
      $('.header').removeClass('scrolled');
    }
  },
  menuInit: function(){
    $('.header__hamburger').on('click', function(){
      $('.header__hamburger, .header, .header__menu').toggleClass('opened');
    })

    $('.menu-item-has-children > a').addClass('no-smoothState');
    
    $('.menu-item-has-children').on('click', function(event){
      event.preventDefault();
      $(this).toggleClass('active');
      var sub = $(this).find('.sub-menu');
      sub.slideToggle(200);
    })
  },
  homeServSlider: function(){
    if (jQuery('.servList__el').length && $(window).outerWidth(true) < 767) { 
      jQuery('.servList__el').not('.slick-initialized').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 4500,
        lazyLoad: 'ondemand',
        speed: 800,
        infinite: false
      }); 
    }
  },
  metodoSlider: function(){
    if (jQuery('.metodo__slider').length) { 
      jQuery('.metodo__slider').not('.slick-initialized').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        autoplay: false,
        autoplaySpeed: 4500,
        lazyLoad: 'ondemand',
        speed: 800,
        infinite: false
      });

      jQuery('.metodo__slider .slick-dots button').each(function(){
        var txt = $(this).text();
        $(this).text('/0'+txt);
      })

      $('.metodo__slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
        $('.slick-dots li.slick-active').addClass('slick-current').prevAll('li').addClass('slick-current');
        $('.slick-dots li.slick-active').nextAll('li').removeClass('slick-current');
      }); 

      $('.metodo__slider .slick-dots li').on('click', function(){
        console.log()
        $(this).addClass('slick-current').prevAll('li').addClass('slick-current');
        $(this).nextAll('li').removeClass('slick-current');
      });  
    }
  },
  partnerSlider: function(){
    if (jQuery('.partner__list').length && $(window).outerWidth(true) < 767) { 
      jQuery('.partner__list').not('.slick-initialized').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 3500,
        lazyLoad: 'ondemand',
        speed: 800,
        infinite: true
      }); 
    }
  },
  pageMarqueeInit: function(){
    $('.servMar__mar').marquee({
      direction: 'left',
      duration: 60000,
      gap: 10,
      startVisible: true,
      duplicated: true,
    });
  },
  scrollToTop: function(){
    $('#totop').on('click', function(){
      window.scrollTo({top: 0, behavior: 'smooth'});
    })
  },
  toggleOrari: function(){
    $('.elSedi__orariTit').on('click', function(){
      $(this).find('.plus').toggleClass('active');
      $(this).siblings('.elSedi__orariWrap').slideToggle(250);
    })
  },
  rebuildAllEvents: function(){
    this.initLoad();
  },
};

global.siteGlobal = siteGlobal;


(function () {
  siteGlobal.initReady();
  $(window).on('load', function() {
    $('#preloader').addClass('loaded');
    siteGlobal.initLoad();
  });

  $(window).on('scroll', function(){
    siteGlobal.initScroll();
  });

  $(window).on('resize', function(){
    siteGlobal.resize();
  })
}());